<div *ngIf="showHeader" class="header p-grid">
  <div class="p-col-3">
    <img src="assets/images/write.png" style="height: 56px" />
  </div>
  <div class="p-col-6">De Berenstad</div>
  <div class="p-col-3">
    <img src="assets/images/writeflipped.png" style="height: 56px" />
  </div>
</div>

<div *ngIf="showHeader" class="p-mb-3 p-text-center title">
  <h1>Inschrijfformulier/offerte {{ soort }}</h1>
  <span *ngIf="soort != ''">Velden met een * zijn verplicht</span>
</div>

<div class="vraagsoort" *ngIf="soort == ''">
  <h2>Waarvoor wilt u uw kind inschrijven ?</h2>
  <p-selectButton
    [options]="soorten"
    [(ngModel)]="soort"
    optionLabel="label"
    optionValue="value"
    (onChange)="kiesSoort()"
  ></p-selectButton>
</div>

<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <p-card
    *ngIf="soort != ''"
    header="Gegevens van het kind"
    [style]="{ 'margin-bottom': '2em' }"
  >
    <div class="p-grid">
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="roepnaam"
          >Roepnaam <span *ngIf="soort == 'bso'">*</span></label
        >
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="roepnaam"
          type="text"
          pInputText
          formControlName="voornaam"
          [style]="{ 'text-transform': 'capitalize' }"
        />
        <app-fielderror [form]="myForm" [field]="'voornaam'"></app-fielderror>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="achternaam">Achternaam <span>*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="achternaam"
          type="text"
          pInputText
          formControlName="achternaam"
          [style]="{ 'text-transform': 'capitalize' }"
        />
        <app-fielderror [form]="myForm" [field]="'achternaam'"></app-fielderror>
      </div>
      <!-- XXXXXXX -->
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="geboortedatum"
          >Geboortedatum<i
            *ngIf="soort == 'kdv'"
            class="pi pi-question-circle"
            style="padding-left: 5px"
            (click)="
              showInfo(
                $event,
                opi,
                'Hier kan ook de uitgerekende datum worden ingevuld'
              )
            "
          ></i
          ><span *ngIf="soort == 'bso'">*</span></label
        >
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <p-calendar
          formControlName="geboortedatum"
          [showIcon]="true"
          inputId="icon"
          dateFormat="dd-mm-yy"
          [locale]="nl"
          placeholder="dd-mm-jjjj"
          [showOnFocus]="false"
        ></p-calendar>
        <app-fielderror
          [form]="myForm"
          [field]="'geboortedatum'"
        ></app-fielderror>
        <!-- XXXXXX -->
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="initialen"
          >Initialen<span *ngIf="soort == 'bso'">*</span></label
        >
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="initialen"
          type="text"
          pInputText
          formControlName="initialen"
          [style]="{ 'text-transform': 'uppercase' }"
        />
        <app-fielderror [form]="myForm" [field]="'initialen'"></app-fielderror>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="adres">Straatnaam <span>*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="adres"
          type="text"
          pInputText
          formControlName="adres"
          [style]="{ 'text-transform': 'capitalize' }"
        />
        <app-fielderror [form]="myForm" [field]="'adres'"></app-fielderror>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="huisnummer">Huisnummer + toevoeging <span>*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="huisnummer"
          type="text"
          pInputText
          formControlName="huisnummer"
        />
        <app-fielderror [form]="myForm" [field]="'huisnummer'"></app-fielderror>
      </div>

      <!-- XXXXXXXXXXXXXX -->
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="postcode">Postcode<span>*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <p-inputMask
          formControlName="postcode"
          mask="9999 aa"
          slotChar=" "
          [style]="{ 'text-transform': 'uppercase' }"
        ></p-inputMask>
        <app-fielderror [form]="myForm" [field]="'postcode'"></app-fielderror>
      </div>
      <!-- XXXXXXXXXXXXXX -->
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="telefoon">Telefoonnummer<span>*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="telefoon"
          type="text"
          pInputText
          formControlName="telefoonnummer"
        />
        <app-fielderror
          [form]="myForm"
          [field]="'telefoonnummer'"
        ></app-fielderror>
      </div>
      <!-- XXXXXXXXXXXXXX -->
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="woonplaats">Woonplaats<span>*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="woonplaats"
          type="text"
          pInputText
          formControlName="woonplaats"
          [style]="{ 'text-transform': 'capitalize' }"
        />
        <app-fielderror [form]="myForm" [field]="'woonplaats'"></app-fielderror>
      </div>
      <!-- XXXXXXXXXXXXXX -->
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="bsn">BSN nummer<span *ngIf="soort == 'bso'">*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input id="bsn" type="text" pInputText formControlName="bsn" />
        <app-fielderror [form]="myForm" [field]="'bsn'"></app-fielderror>
      </div>
      <!-- XXXXXXXXXXXXXX -->
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-4 p-lg-2">
        <label for="bsn">Geslacht</label>
      </div>
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-8 p-lg-4">
        <p-dropdown
          [options]="['Jongen', 'Meisje']"
          formControlName="geslacht"
        ></p-dropdown>
        <app-fielderror [form]="myForm" [field]="'huisarts'"></app-fielderror>
      </div>
      <!-- XXXXXXXXXXXXXX -->
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-4 p-lg-2">
        <label for="bsn">Nationaliteit</label>
      </div>
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-8 p-lg-4">
        <input
          id="huisarts"
          type="text"
          pInputText
          formControlName="nationaliteit"
        />
        <app-fielderror [form]="myForm" [field]="'huisarts'"></app-fielderror>
      </div>
      <!-- XXXXXXXXXXXXXX -->
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-4 p-lg-2">
        <label for="bsn">Huisarts</label>
      </div>
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-8 p-lg-4">
        <input
          id="huisarts"
          type="text"
          pInputText
          formControlName="huisarts"
        />
        <app-fielderror [form]="myForm" [field]="'huisarts'"></app-fielderror>
      </div>
      <!-- XXXXXXXXXXXXXX -->
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-4 p-lg-2">
        <label for="bsn">Gezondheidscentrum</label>
      </div>
      <div *ngIf="soort == 'psz'" class="p-col-12 p-md-8 p-lg-4">
        <input
          id="huisarts"
          type="text"
          pInputText
          formControlName="gezondheidscentrum"
        />
        <app-fielderror [form]="myForm" [field]="'huisarts'"></app-fielderror>
      </div>

      <!-- tot hier !!!!!!!!! -->
    </div>
  </p-card>

  <p-card
    *ngIf="soort != ''"
    header="Noodnummer"
    [style]="{ 'margin-bottom': '2em' }"
  >
    <div class="p-grid">
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="relatie">Naam/Relatie</label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <span class="p-input-icon-right fullwidth">
          <i
            class="pi pi-question-circle"
            (click)="
              showInfo(
                $event,
                opi,
                'Vul hier de naam en relatie in van degene die uw kind opvangt indien u niet bereikbaar bent'
              )
            "
          ></i>
          <input
            id="relatie"
            type="text"
            pInputText
            formControlName="relatie"
            [style]="{ 'text-transform': 'capitalize' }"
          />
        </span>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="relatie">Telefoonnummer</label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <span class="p-input-icon-right fullwidth">
          <i
            class="pi pi-question-circle"
            (click)="
              showInfo(
                $event,
                opi,
                'Vul hier het telefoonnummer in van degene die uw kind opvangt indien u niet bereikbaar bent'
              )
            "
          ></i>
          <input
            id="relatie"
            type="text"
            pInputText
            formControlName="relatietelefoonnummer"
          />
        </span>
      </div>
    </div>
  </p-card>

  <p-card
    *ngIf="soort == 'bso'"
    header="Gegevens basisschool"
    [style]="{ 'margin-bottom': '2em' }"
  >
    <div class="p-grid">
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="school">School<span *ngIf="soort == 'bso'">*</span></label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="school"
          type="text"
          pInputText
          formControlName="school"
          [style]="{ 'text-transform': 'capitalize' }"
        />
        <app-fielderror [form]="myForm" [field]="'school'"></app-fielderror>
      </div>
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="schoolgroep">Groep</label>
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <input
          id="schoolgroep"
          type="text"
          pInputText
          formControlName="schoolgroep"
        />
      </div>
    </div>
  </p-card>

  <div class="p-grid" *ngIf="soort != ''">
    <div class="p-col-12 p-md-6 p-lg-6">
      <p-card [style]="{ 'margin-bottom': '2em' }">
        <ng-template pTemplate="header">
          <div class="p-card-title title_extra">
            <span> Gegevens aanvrager</span>

            <i
              class="pi pi-question-circle"
              style="padding-left: 5px"
              (click)="
                showInfo(
                  $event,
                  opi,
                  'Deze gegevens zijn nodig voor uw aanvraag voor kinderopvangtoeslag'
                )
              "
            ></i></div
        ></ng-template>

        <div class="p-grid">
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvragernaam"
              >Achternaam<span *ngIf="soort == 'bso'">*</span></label
            >
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="aanvragernaam"
              type="text"
              pInputText
              formControlName="aanvragernaam"
              [style]="{ 'text-transform': 'capitalize' }"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'aanvragernaam'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvragerinitialen"
              >Initialen<span *ngIf="soort == 'bso'">*</span></label
            >
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="aanvragerinitialen"
              type="text"
              pInputText
              formControlName="aanvragerinitialen"
              [style]="{ 'text-transform': 'uppercase' }"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'aanvragerinitialen'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvragervoorvoegsel">Voorvoegsel</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="aanvragervoorvoegsel"
              type="text"
              pInputText
              formControlName="aanvragervoorvoegsel"
              [style]="{ 'text-transform': 'capitalize' }"
            />
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvragergeboortedatum"
              >Geboortedatum<span *ngIf="soort == 'bso'">*</span></label
            >
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <p-calendar
              formControlName="aanvragergeboortedatum"
              [showIcon]="true"
              inputId="icon"
              dateFormat="dd-mm-yy"
              [locale]="nl"
              placeholder="dd-mm-jjjj"
              [showOnFocus]="false"
            ></p-calendar>
            <app-fielderror
              [form]="myForm"
              [field]="'aanvragergeboortedatum'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvragertelefoonnummer"
              >Telefoonnummer<span *ngIf="soort == 'bso'">*</span></label
            >
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="aanvragertelefoonnummer"
              type="text"
              pInputText
              formControlName="aanvragertelefoonnummer"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'aanvragertelefoonnummer'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvragerbsn"
              >BSN nummer<span *ngIf="soort == 'bso'">*</span></label
            >
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="aanvragerbsn"
              type="text"
              pInputText
              formControlName="aanvragerbsn"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'aanvragerbsn'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvrageremail">Email adres<span>*</span></label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="aanvrageremail"
              type="email"
              pInputText
              formControlName="aanvrageremail"
              [style]="{ 'text-transform': 'lowercase' }"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'aanvrageremail'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="aanvrageremail"
              >Email adres controle<span>*</span></label
            >
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="aanvrageremailcontrole"
              type="email"
              pInputText
              formControlName="aanvrageremailcontrole"
              [style]="{ 'text-transform': 'lowercase' }"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'aanvrageremailcontrole'"
            ></app-fielderror>
            <div
              class="p-error"
              *ngIf="myForm.errors && (myForm.touched || myForm.dirty)"
            >
              Email velden zijn niet gelijk !!
            </div>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4" *ngIf="soort == 'psz'">
            <label for="aanvragerlandherkomst">Land van herkomst</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8" *ngIf="soort == 'psz'">
            <input
              id="aanvragerlandherkomst"
              type="test"
              pInputText
              formControlName="aanvragerlandherkomst"
            />
          </div>
          <div class="p-col-12 p-md-4 p-lg-4" *ngIf="soort == 'psz'">
            <label for="aanvragernationaliteit">Nationaliteit</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8" *ngIf="soort == 'psz'">
            <input
              id="aanvragernationaliteit"
              type="test"
              pInputText
              formControlName="aanvragernationaliteit"
            />
          </div>
          <div class="p-col-12 p-md-4 p-lg-4" *ngIf="soort == 'psz'">
            <label for="aanvrageropleiding">Opleiding</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8" *ngIf="soort == 'psz'">
            <input
              id="aanvrageropleiding"
              type="test"
              pInputText
              formControlName="aanvrageropleiding"
            />
          </div>
        </div>
      </p-card>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6">
      <p-card [style]="{ 'margin-bottom': '2em' }">
        <ng-template pTemplate="header">
          <div class="p-card-title title_extra">
            <span> Gegevens partner</span>

            <i
              class="pi pi-question-circle"
              style="padding-left: 5px"
              (click)="
                showInfo(
                  $event,
                  opi,
                  'Deze gegevens zijn nodig voor uw aanvraag voor kinderopvangtoeslag'
                )
              "
            ></i></div
        ></ng-template>
        <div class="p-grid">
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="partnernaam">Achternaam</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="partnernaam"
              type="text"
              pInputText
              formControlName="partnernaam"
              [style]="{ 'text-transform': 'capitalize' }"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'partnernaam'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="partnerinitialen">Initialen</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="partnerinitialen"
              type="text"
              pInputText
              formControlName="partnerinitialen"
              [style]="{ 'text-transform': 'uppercase' }"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'partnerinitialen'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="partnervoorvoegsel">Voorvoegsel</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="partnervoorvoegsel"
              type="text"
              pInputText
              formControlName="partnervoorvoegsel"
              [style]="{ 'text-transform': 'capitalize' }"
            />
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="partnergeboortedatum">Geboortedatum</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <p-calendar
              formControlName="partnergeboortedatum"
              [showIcon]="true"
              inputId="icon"
              dateFormat="dd-mm-yy"
              [locale]="nl"
              placeholder="dd-mm-jjjj"
              [showOnFocus]="false"
            ></p-calendar>
            <app-fielderror
              [form]="myForm"
              [field]="'partnergeboortedatum'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="partnertelefoonnummer">Telefoonnummer</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="partnertelefoonnummer"
              type="text"
              pInputText
              formControlName="partnertelefoonnummer"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'partnertelefoonnummer'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="partnerbsn">BSN nummer</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="partnerbsn"
              type="text"
              pInputText
              formControlName="partnerbsn"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'partnerbsn'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4">
            <label for="partneremail">Email adres</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8">
            <input
              id="partneremail"
              type="email"
              pInputText
              formControlName="partneremail"
              [style]="{ 'text-transform': 'lowercase' }"
            />
            <app-fielderror
              [form]="myForm"
              [field]="'partneremail'"
            ></app-fielderror>
          </div>
          <div class="p-col-12 p-md-4 p-lg-4" *ngIf="soort == 'psz'">
            <label for="partnerlandherkomst">Land van herkomst</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8" *ngIf="soort == 'psz'">
            <input
              id="partnerlandherkomst"
              type="test"
              pInputText
              formControlName="partnerlandherkomst"
            />
          </div>
          <div class="p-col-12 p-md-4 p-lg-4" *ngIf="soort == 'psz'">
            <label for="partnernationaliteit">Nationaliteit</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8" *ngIf="soort == 'psz'">
            <input
              id="partnernationaliteit"
              type="test"
              pInputText
              formControlName="partnernationaliteit"
            />
          </div>
          <div class="p-col-12 p-md-4 p-lg-4" *ngIf="soort == 'psz'">
            <label for="partneropleiding">Opleiding</label>
          </div>
          <div class="p-col-12 p-md-8 p-lg-8" *ngIf="soort == 'psz'">
            <input
              id="partneropleiding"
              type="test"
              pInputText
              formControlName="partneropleiding"
            />
          </div>
        </div>
      </p-card>
    </div>
  </div>

  <p-card
    *ngIf="soort != ''"
    header="Plaatsing"
    [style]="{ 'margin-bottom': '2em' }"
  >
    <div class="p-grid">
      <div class="p-col-12 p-md-4 p-lg-2">
        <label for="plaatsing"
          >Gewenste ingangsdatum<span *ngIf="soort == 'bso'">*</span></label
        >
      </div>
      <div class="p-col-12 p-md-8 p-lg-4">
        <p-calendar
          formControlName="plaatsing"
          [showIcon]="true"
          inputId="icon"
          dateFormat="dd-mm-yy"
          [locale]="nl"
          placeholder="dd-mm-jjjj"
          [showOnFocus]="false"
        ></p-calendar>
        <app-fielderror [form]="myForm" [field]="'plaatsing'"></app-fielderror>
      </div>
      <div *ngIf="soort == 'kdv'" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-lg-2">
            <label>Tijden</label
            ><i
              class="pi pi-question-circle"
              style="padding-left: 5px"
              (click)="
                showInfo(
                  $event,
                  opi,
                  'Vul hier de tijden in waarop uw kind gebruik maakt van de opvang. Let op: wij hanteren alleen hele en halve uren'
                )
              "
            ></i>
          </div>

          <div class="p-col-12 p-md-6 p-lg-2">
            <label class="p-col-12 p-md-6 p-lg-2">maandag</label>
            <p-dropdown
              formControlName="maandagvan"
              [options]="tijden"
              placeholder="van"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
            <p-dropdown
              formControlName="maandagtot"
              [options]="tijden"
              placeholder="tot"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
          </div>
          <div class="p-col-12 p-md-6 p-lg-2">
            <label class="p-col-12 p-md-6 p-lg-2">dinsdag</label>
            <p-dropdown
              formControlName="dinsdagvan"
              [options]="tijden"
              placeholder="van"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
            <p-dropdown
              formControlName="dinsdagtot"
              [options]="tijden"
              placeholder="tot"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
          </div>
          <div class="p-col-12 p-md-6 p-lg-2">
            <label class="p-col-12 p-md-6 p-lg-2">woensdag</label>
            <p-dropdown
              formControlName="woensdagvan"
              [options]="tijden"
              placeholder="van"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
            <p-dropdown
              formControlName="woensdagtot"
              [options]="tijden"
              placeholder="tot"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
          </div>
          <div class="p-col-12 p-md-6 p-lg-2">
            <label class="p-col-12 p-md-6 p-lg-2">donderdag</label>
            <p-dropdown
              formControlName="donderdagvan"
              [options]="tijden"
              placeholder="van"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
            <p-dropdown
              formControlName="donderdagtot"
              [options]="tijden"
              placeholder="tot"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
          </div>
          <div class="p-col-12 p-md-6 p-lg-2">
            <label class="p-col-12 p-md-6 p-lg-2">vrijdag</label>
            <p-dropdown
              formControlName="vrijdagvan"
              [options]="tijden"
              placeholder="van"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
            <p-dropdown
              formControlName="vrijdagtot"
              [options]="tijden"
              placeholder="tot"
              optionLabel="name"
              optionValue="code"
              [showClear]="true"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div *ngIf="soort == 'bso'" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-12 p-lg-12">
            <label>Pakket</label>
            <i
              class="pi pi-question-circle"
              style="padding-left: 5px"
              (click)="showInfo($event, opp, pakketuitleg)"
            ></i>
          </div>
          <div class="p-col-2 p-md-2 p-lg-2" style="text-align: center">
            <label></label>
          </div>
          <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
            <label>ma</label>
          </div>
          <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
            <label>di</label>
          </div>
          <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
            <label>wo</label>
          </div>
          <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
            <label>do</label>
          </div>
          <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
            <label>vr</label>
          </div>
          <div class="p-grid p-col-12" *ngFor="let pakket of pakketten">
            <div class="p-col-2 p-md-2 p-lg-2">{{ pakket.pakket }}</div>
            <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
              <p-checkbox
                name="groupname"
                [binary]="true"
                [(ngModel)]="pakket.maandag"
                [ngModelOptions]="{ standalone: true }"
              ></p-checkbox>
            </div>
            <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
              <p-checkbox
                name="groupname"
                [binary]="true"
                [(ngModel)]="pakket.dinsdag"
                [ngModelOptions]="{ standalone: true }"
              ></p-checkbox>
            </div>
            <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
              <p-checkbox
                name="groupname"
                [binary]="true"
                [(ngModel)]="pakket.woensdag"
                [ngModelOptions]="{ standalone: true }"
              ></p-checkbox>
            </div>
            <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
              <p-checkbox
                name="groupname"
                [binary]="true"
                [(ngModel)]="pakket.donderdag"
                [ngModelOptions]="{ standalone: true }"
              ></p-checkbox>
            </div>
            <div class="p-col-2 p-md-2 p-lg-1" style="text-align: center">
              <p-checkbox
                name="groupname"
                [binary]="true"
                [(ngModel)]="pakket.vrijdag"
                [ngModelOptions]="{ standalone: true }"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>

  <p-card
    *ngIf="soort != ''"
    [style]="{ 'margin-bottom': '2em' }"
    header="Algemeen"
  >
    <div class="p-grid">
      <div class="p-col-12">
        <div *ngIf="soort == 'kdv'" class="p-col-12 p-md-4 p-lg-4">
          <label for="plaatsing"
            >Mijn kind mag mee met uitstapjes buiten de kinderopvang</label
          >
        </div>
        <div *ngIf="soort == 'kdv'" class="p-col-12 p-md-8 p-lg-4">
          <p-selectButton
            [options]="uitstapjeOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="uitstapje"
          ></p-selectButton>
        </div>
      </div>
      <div *ngIf="soort != 'psz'" class="p-col-12">
        <div class="p-col-12 p-md-4 p-lg-4">
          <label for="plaatsing"
            >Heeft u belangstelling om deel te nemen aan de
            oudercommissie?</label
          >
        </div>
        <div class="p-col-12 p-md-8 p-lg-4">
          <p-selectButton
            [options]="commissieOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="commissie"
          ></p-selectButton>
        </div>
      </div>
      <div *ngIf="soort == 'psz'" class="p-grid p-col-12">
        <div class="p-col-3"><label>Heeft u meer kinderen?</label></div>
        <div class="p-col-9">
          <p-selectButton
            [options]="janeeOptions"
            formControlName="meerkinderen"
          ></p-selectButton>
        </div>
      </div>
      <div
        *ngIf="soort == 'psz' && myForm.value.meerkinderen == 'Ja'"
        class="p-grid p-col-12"
      >
        <div class="p-col-3"><label>Op welke basisschool ?</label></div>
        <div class="p-col-9">
          <input
            id="meerkinderenbasisschool"
            type="text"
            pInputText
            formControlName="meerkinderenbasisschool"
          />
        </div>
      </div>
      <div *ngIf="soort == 'psz'" class="p-grid p-col-12">
        <div class="p-col-3">
          <label>Heeft uw kind de gebruikelijke inentingen gehad?</label>
        </div>
        <div class="p-col-9">
          <p-selectButton
            [options]="janeeOptions"
            formControlName="inentingen"
          ></p-selectButton>
        </div>
      </div>
      <div *ngIf="soort == 'psz'" class="p-grid p-col-12">
        <div class="p-col-3"><label>Gebruikt uw kind medicijnen?</label></div>
        <div class="p-col-9">
          <p-selectButton
            [options]="janeeOptions"
            [(ngModel)]="bMedicijnen"
            [ngModelOptions]="{ standalone: true }"
          ></p-selectButton>
        </div>
      </div>
      <div
        *ngIf="soort == 'psz' && bMedicijnen == 'Ja'"
        class="p-grid p-col-12"
      >
        <div class="p-col-3">
          <label>Welke medicijnen gebruikt uw kind?</label>
        </div>
        <div class="p-col-9">
          <input
            id="medicijnen"
            type="text"
            pInputText
            formControlName="medicijnen"
          />
        </div>
      </div>
      <div *ngIf="soort == 'psz'" class="p-grid p-col-12">
        <div class="p-col-3"><label>Is uw kind allergisch?</label></div>
        <div class="p-col-9">
          <p-selectButton
            [options]="janeeOptions"
            [(ngModel)]="bAllergie"
            [ngModelOptions]="{ standalone: true }"
          ></p-selectButton>
        </div>
      </div>
      <div *ngIf="soort == 'psz' && bAllergie == 'Ja'" class="p-grid p-col-12">
        <div class="p-col-3">
          <label>Waarvoor is uw kind allergisch?</label>
        </div>
        <div class="p-col-9">
          <input
            id="allergie"
            type="text"
            pInputText
            formControlName="allergie"
          />
        </div>
      </div>
      <div *ngIf="soort == 'psz'" class="p-grid p-col-12">
        <div class="p-col-3">
          <label>Welke taal wordt er thuis gesproken?</label>
        </div>
        <div class="p-col-9">
          <input id="taal" type="text" pInputText formControlName="taal" />
        </div>
      </div>
      <div *ngIf="soort == 'psz'" class="p-col-12">
        <label>Inkomens situatie ouder(s)/verzorger(s)</label><br />
        <label>Welke situatie is bij u van toepassing?</label>
        <div
          *ngFor="let situatie of situaties"
          class="field-checkbox"
          style="margin-top: 5px"
        >
          <p-radioButton
            [inputId]="situatie.key"
            name="situatie"
            [value]="situatie"
            formControlName="situatie"
          ></p-radioButton>
          <label [for]="situatie.key" style="margin-left: 10px">{{
            situatie.name
          }}</label>
        </div>
        <div *ngIf="myForm.value.situatie.extra > ''">
          <h3>{{ myForm.value.situatie.extra }}</h3>
          <h4>
            Een inkomensverklaring kunt
            <a href="http://www.belastingdienst.nl/" target="_blank">hier</a> u
            downloaden via de website van de belastingdienst.
          </h4>
        </div>
      </div>
      <div *ngIf="soort == 'psz'" class="p-col-12">
        <label>Zijn er dagdelen dat u beslist niet kan ?</label>

        <div
          *ngFor="let dagdeel of dagDelen"
          class="field-checkbox"
          style="margin-top: 5px"
        >
          <p-checkbox
            name="group2"
            value="dagdeel"
            [value]="dagdeel.name"
            [formControl]="myForm.controls['dagdelen']"
            [inputId]="dagdeel.key"
          ></p-checkbox>
          <label [for]="dagdeel.key" style="margin-left: 10px">{{
            dagdeel.name
          }}</label>
        </div>
      </div>
    </div></p-card
  >

  <p-card
    *ngIf="soort != ''"
    header="Bijzonderheden"
    [style]="{ 'margin-bottom': '2em' }"
  >
    <textarea
      rows="5"
      cols="30"
      pInputTextarea
      autoResize="autoResize"
      formControlName="bijzonderheden"
    ></textarea>
  </p-card>

  <!-- <p-button label="Versturen" type="button" (click)="onSubmit()"></p-button> -->
</form>

<div class="p-grid" *ngIf="soort != ''">
  <div class="p-col-12" style="text-align: center">
    <p-button
      [disabled]="!myForm.valid"
      label="Opsturen"
      type="button"
      (click)="onSubmit()"
    ></p-button>

    <p-button label="Annuleren" (click)="closeWindow()"></p-button>
  </div>
</div>
<div
  *ngIf="soort > '' && myForm.touched && !myForm.valid"
  style="text-align: center"
>
  <label class="text-danger"
    >Er is nog een probleem met de door u ingevulde waarden</label
  >
</div>
<!-- <button
  type="text"
  pButton
  label="Versturen"
  (click)="dspCatcha = true"
></button> -->

<p-dialog header="Title" [(visible)]="dspCatcha">
  <p-captcha
    siteKey="6Lf5YBMTAAAAAL3racC7ceZSkJJkh7zrkkMYtpPr"
    (onResponse)="captcha($event)"
  ></p-captcha>
</p-dialog>

<p-overlayPanel #opi>
  <ng-template pTemplate>
    {{ info }}
  </ng-template>
</p-overlayPanel>

<p-dialog
  [(visible)]="displayThanks"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [closeOnEscape]="false"
>
  <div class="p-col-11 p-md-8 p-lg-12 p-text-center">
    <p></p>
    <h3 class="p-m-0">
      Dank u voor het invullen van het inschrijvingsformulier
    </h3>
    <p></p>
    <h3 class="p-m-0">
      U krijgt een bevestiging van uw inschrijving op het door u opgegeven email
      adres
    </h3>
  </div>

  <ng-template pTemplate="footer">
    <div class="p-mb-3 p-text-center">
      <p-button
        icon="pi pi-check"
        (click)="closeWindow()"
        label="Ok"
        styleClass="p-button-primary"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<p-overlayPanel #opp appendTo="body">
  <ng-template pTemplate>
    <div style="width: 50vw; height: 50vh; overflow: auto">
      <h3>
        Klik hier aan op welke dagen uw kind gebruik maakt van welk pakket
      </h3>
      <p></p>
      <h4>BSO</h4>
      Uw kind heeft het hele jaar recht op opvang na schooltijd op de
      overeengekomen dagdelen* BSO de Berenstad vangt uw kind op wanneer het ’s
      middags uit school komt en zorgt dat het veilig op de opvang locatie
      aankomt. Daar mag Uw kind verblijven tot 18.30 uur. Tijdens rooster vrije
      dagen of vakanties zorgt u ervoor dat uw kind op de BSO wordt gebracht en
      is uw kind welkom vanaf de tijd dat normaal gesproken de school uit gaat.
      Ook tijdens onverwachte vrije dagen ( denk aan ziekte van leerkrachten
      e.d. zal, in overleg met de directeur van de school) opvang worden
      aangeboden aan hen die daar gebruik van willen maken.

      <p></p>
      <h4>BSO vakantie:</h4>
      Uw kind heeft recht op opvang tijdens schoolvakanties en rooster vrije
      dagen op de overeengekomen dagdelen* U kunt uw kind tijdens roostervrije
      dagen en vakanties brengen op de BSO locatie vanaf 8.00 uur. Uw kind kan
      daar opgevangen worden tot 18.30 uur.

      <p></p>
      <h4>BSO Totaal:</h4>
      Uw kind heeft het hele jaar recht op opvang na schooltijd, in vakanties en
      op rooster vrije dagen op de overeen gekomen dagdelen* BSO de Berenstad
      vangt uw kind op wanneer het ’s middags uit school komt en zorgt dat het
      veilig op de opvang locatie aankomt. Daar mag uw kind verblijven tot 18.30
      uur. Tijdens roostervrije dagen en vakanties kunt u uw kind brengen op de
      BSO locatie vanaf 8.00 uur ’s morgens en zijn we geopend tot 18.30 uur.

      <p></p>
      <h4>VSO:</h4>

      Voorschoolse opvang. Uw kind heeft het hele jaar recht op opvang voor
      aanvang van school, in vakanties en op rooster vrije dagen, op de overeen
      gekomen dagdelen*. BSO de Berenstad vangt uw kind op vanaf 07.00 uur ’s
      morgens en zorgt dat uw kind, wanneer de school aanvangt, om 08.30 uur in
      zijn of haar klas terecht komt. * M.u.v. landelijk erkende feestdagen.

      <p>
        Eventuele wensen m.b.t. de opvangpaketten kan altijd besproken worden.
        **
        <br />
        Het is mogelijk om elk gewenst pakket af te nemen, of te combineren met
        andere pakketten zonder bijkomende kosten. Alles is bespreekbaar.
      </p>
    </div>
  </ng-template>
</p-overlayPanel>
