<div
  *ngIf="
    (form.get(field).invalid && form.get(field).touched) ||
    form.get(field).dirty
  "
>
  <small *ngIf="form.get(field).errors?.required" class="p-error"
    >Dit veld is verplicht</small
  >
  <!--question mark(?) is a safe navigation operator-->
  <small *ngIf="form.get(field).errors?.pattern" class="p-error"
    >Voer aub een correcte waarde in</small
  >
</div>
