import { Injectable } from '@angular/core';
import { Config } from './config';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InitService {
  exit = false;

  constructor(public _http: HttpClient, public _config: Config) {}

  async initialize() {
    console.log('Start initialization');

    await this.loadConfig();

    return new Promise((resolve) => {
      resolve(true);
    });
  }

  loadConfig() {
    return new Promise<void>((resolve) => {
      console.log('Load config');
      this._config.load().subscribe((env_data) => {
        console.log('Config loaded');
        resolve();
      });
    });
  }
}
