export class Formulier {
  constructor(
    public soort: string,
    public lokatie: string,
    public voornaam: string,
    public achternaam: string,
    public initialen: string,
    public geboortedatum: Date,
    public adres: string,
    public huisnummer: string,
    public postcode: string,
    public woonplaats: string,
    public telefoonnummer: string,
    public bsn: string,
    public noodnummer: string,
    public relatie: string,
    public relatietelefoonnummer: string,
    public uitstapje: string,
    public aanvragernaam: string,
    public aanvragerinitialen: string,
    public aanvragervoorvoegsel: string,
    public aanvragergeboortedatum: Date,
    public aanvragertelefoonnummer: string,
    public aanvragerbsn: string,
    public aanvrageremail: string,
    public aanvrageradres: string,
    public aanvragerpostcode: string,
    public aanvragerwoonplaats: string,
    public aanvragertelefoon: string,
    public aanvragerlandherkomst: string,
    public aanvragernationaliteit: string,
    public aanvrageropleiding: string,
    public partnernaam: string,
    public partnerinitialen: string,
    public partnervoorvoegsel: string,
    public partnergeboortedatum: Date,
    public partnertelefoonnummer: string,
    public partnerbsn: string,
    public partneremail: string,
    public partneradres: string,
    public partnerpostcode: string,
    public partnerwoonplaats: string,
    public partnertelefoon: string,
    public partnerlandherkomst: string,
    public partnernationaliteit: string,
    public partneropleiding: string,
    public plaatsing: string,
    public maandagvan: string,
    public maandagtot: string,
    public dinsdagvan: string,
    public dinsdagtot: string,
    public woensdagvan: string,
    public woensdagtot: string,
    public donderdagvan: string,
    public donderdagtot: string,
    public vrijdagvan: string,
    public vrijdagtot: string,
    public commissie: string,
    public bijzonderheden: string,
    public pakket: object,
    public school: string,
    public schoolgroep: string,
    public waverzekering: string,
    public zkverzekering: string,
    public nieuw: boolean,
    public meerkinderen: string,
    public meerkinderenbasisschool: string,
    public inentingen: string,
    public medicijnen: string,
    public allergie: string,
    public taal: string,
    public situatie: Object,
    public dagdelen: string,
    public huisarts: string,
    public gezondheidscentrum: string,
    public geslacht: string,
    public nationaliteit: string
  ) {}
}
