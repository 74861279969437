import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InschrijfComponent } from './inschrijf/inschrijf.component';
import { PrimengModule } from './primeng.module';
import { routing } from './app.routing';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { Config } from './shared/config';
import { HttpClientModule } from '@angular/common/http';
import { InitService } from './shared/init.service';
import { DankComponent } from './dank/dank.component';
import { MessageService } from 'primeng/api';
import { FielderrorComponent } from './shared/fieldError/fielderror.component';

export function initServiceFactory(init: InitService) {
  return () => init.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    InschrijfComponent,
    DankComponent,
    FielderrorComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    routing,
    PrimengModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    Config,
    HttpClientModule,
    InitService,
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initServiceFactory,
      deps: [InitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
