import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dank',
  templateUrl: './dank.component.html',
  styleUrls: ['./dank.component.scss']
})
export class DankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
