import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class Config {
  public settings: Object;

  constructor(private http: HttpClient) {}

  load() {
    console.log('load config constructor');

    // return new Promise((resolve) => {

    return this.http.get('assets/config.json').pipe(
      tap((config) => {
        console.log('Configuration loaded...........');
        this.settings = config;
        // resolve();
      }),
      catchError(this.handleError)
    );
  }

  get(key: any) {
    return this.settings[key];
  }

  private handleError(error: any) {
    console.error('Error : ' + error.message);
    return Observable.throw(error || 'Server error!');
  }
}
