import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Formulier } from '../shared/formulier';
import { Config } from '../shared/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';

/** Mail controle check */
export const emailEqualValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const email = control.get('aanvrageremail');
  const emailcontrole = control.get('aanvrageremailcontrole');
  return email.value > '' &&
    emailcontrole.value > '' &&
    email.value != emailcontrole.value
    ? { emailCorrect: true }
    : null;
};

@Component({
  selector: 'app-inschrijf',
  templateUrl: './inschrijf.component.html',
  styleUrls: ['./inschrijf.component.scss'],
})
export class InschrijfComponent implements OnInit {
  uitstapjeOptions: any[];
  groepOptions: any[];
  commissieOptions: any[];
  myForm: FormGroup;
  formulier: Formulier;
  dspCatcha = false;
  showHeader = true;
  info = '';
  nl: any;
  soort = '';
  soorten = [
    { label: 'Kinderopvang', value: 'kdv' },
    { label: 'Buitenschoolse opvang', value: 'bso' },
    { label: 'Peuterspeelzaal', value: 'psz' },
  ];
  lokatie = '';
  pakketten: any[];
  dagdelen = [];
  selectedSituatie = '';
  bMedicijnen = false;
  bAllergie = false;
  situaties = [
    {
      name: 'Ik woon alleen met mijn kind en heb geen werk of studeer niet.',
      extra: 'U dient een inkomensverklaring af te geven.',
      key: 1,
    },
    {
      name: 'Ik woon alleen met mijn kind en werk of studeer.',
      key: 2,
    },
    {
      name: 'Ik woon samen met een partner en ik werk niet of studeer niet en mijn partner werkt niet of studeert niet.',
      extra:
        'U dient van beide ouders/verzorgers een inkomensverklaring af te geven.',
      key: 3,
    },
    {
      name: 'Ik woon samen met een partner en ik heb werk of studeer en mijn partner werkt niet of studeert niet.',
      extra:
        'U dient van beide ouders/verzorgers een inkomensverklaring af te geven.',
      key: 4,
    },
    {
      name: 'Ik woon samen met een partner en ik heb werk of studeer en mijn partner werkt of studeert.',
      key: 5,
    },
  ];

  bDagdelen = false;
  dagDelen = [
    {
      name: 'Maandagochtend',
      key: 'Maandagochtend',
    },
    {
      name: 'Maandagmiddag',
      key: 'Maandagmiddag',
    },
    {
      name: 'Dinsdagochtend',
      key: 'Dinsdagochtend',
    },
    {
      name: 'Dinsdagmiddag',
      key: 'Dinsdagmiddag',
    },
    {
      name: 'Woensdagochtend',
      key: 'Woensdagochtend',
    },
    {
      name: 'Woensdagmiddag',
      key: 'Woensdagmiddag',
    },
    {
      name: 'Donderdagochtend',
      key: 'Donderdagochtend',
    },
    {
      name: 'Donderdagmiddag',
      key: 'Donderdagmiddag',
    },
    {
      name: 'Vrijdagochtend',
      key: 'Vrijdagochtend',
    },
    {
      name: 'Vrijdagmiddag',
      key: 'Vrijdagmiddag',
    },
  ];

  displayThanks: boolean = false;
  // time: RegExp = /^[^<>*!]+$/;
  time: RegExp = /^(20)[0-5]\d$/;

  tijden = [];

  janeeOptions = [
    { label: 'Nee', value: 'Nee' },
    { label: 'Ja', value: 'Ja' },
  ];

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  pakketuitleg = `
  <h1>test</h1>


  `;

  constructor(
    private _config: Config,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    public messageService: MessageService,
    private primeConfig: PrimeNGConfig
  ) {
    this.myForm = formBuilder.group(
      {
        voornaam: ['', requiredIfValidator(() => this.soort === 'bso')],
        achternaam: ['', Validators.required],
        initialen: ['', requiredIfValidator(() => this.soort === 'bso')],
        geboortedatum: ['', requiredIfValidator(() => this.soort === 'bso')],
        adres: ['', Validators.required],
        huisnummer: ['', Validators.required],
        postcode: ['', Validators.required],
        woonplaats: ['', Validators.required],
        telefoonnummer: ['', Validators.required],
        bsn: [
          '',
          [
            requiredIfValidator(() => this.soort === 'bso'),
            Validators.pattern('^[0-9]{9}$'),
          ],
        ],
        noodnummer: [''],
        relatie: [''],
        relatietelefoonnummer: [''],
        uitstapje: [''],
        aanvragernaam: ['', requiredIfValidator(() => this.soort === 'bso')],
        aanvragerinitialen: [
          '',
          requiredIfValidator(() => this.soort === 'bso'),
        ],
        aanvragervoorvoegsel: [''],
        aanvragergeboortedatum: [
          '',
          requiredIfValidator(() => this.soort === 'bso'),
        ],
        aanvragertelefoonnummer: [
          '',
          requiredIfValidator(() => this.soort === 'bso'),
        ],
        aanvragerbsn: [
          '',
          [
            requiredIfValidator(() => this.soort === 'bso'),
            Validators.pattern('^[0-9]{9}$'),
          ],
        ],
        aanvrageremail: [
          '',
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        aanvrageremailcontrole: [
          '',
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        aanvrageradres: [null],
        aanvragerpostcode: [null],
        aanvragerwoonplaats: [null],
        aanvragertelefoon: [null],
        aanvragerlandherkomst: [null],
        aanvragernationaliteit: [null],
        aanvrageropleiding: [null],
        partnernaam: [''],
        partnerinitialen: [''],
        partnervoorvoegsel: [''],
        partnergeboortedatum: [''],
        partnertelefoonnummer: [''],
        partnerbsn: ['', Validators.pattern('^[0-9]{9}$')],
        partneremail: [
          '',
          [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
        ],
        partneradres: [null],
        partnerpostcode: [null],
        partnerwoonplaats: [null],
        partnertelefoon: [null],
        partnerlandherkomst: [null],
        partnernationaliteit: [null],
        partneropleiding: [null],
        plaatsing: ['', requiredIfValidator(() => this.soort === 'bso')],
        maandagvan: [null],
        maandagtot: [null],
        dinsdagvan: [null],
        dinsdagtot: [null],
        woensdagvan: [null],
        woensdagtot: [null],
        donderdagvan: [null],
        donderdagtot: [null],
        vrijdagvan: [null],
        vrijdagtot: [null],
        commissie: [''],
        bijzonderheden: [''],
        school: ['', requiredIfValidator(() => this.soort === 'bso')],
        schoolgroep: [''],
        waverzekering: [''],
        zkverzekering: [''],
        situatie: [''],
        meerkinderen: [false],
        meerkinderenbasisschool: [null],
        inentingen: [null],
        medicijnen: [null],
        allergie: [null],
        taal: [null],
        dagdelen: [['']],
        huisarts: [null],
        gezondheidscentrum: [null],
        geslacht: [null],
        nationaliteit: [null],
      },
      { validators: emailEqualValidator }
    );

    this.uitstapjeOptions = [
      { label: 'Nee', value: 'Nee' },
      { label: 'Ja', value: 'Ja' },
    ];

    this.groepOptions = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
    ];
    this.commissieOptions = [
      { label: 'Ja', value: 'ja' },
      { label: 'Nee', value: 'nee' },
      { label: 'Misschien', value: 'misschien' },
    ];
    this.nl = {
      firstDayOfWeek: 1,
    };
  }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe((params) => {
      if (params.soort) {
        this.soort = params.soort;
      }
      if (params.lokatie) {
        this.lokatie = params.lokatie;
      }
      if (params.iframe == 'yes') {
        this.showHeader = false;
      }
    });

    this.primeConfig.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      dayNames: [
        'Zondag',
        'Maandag',
        'Dinsdag',
        'Woensdag',
        'Donderdag',
        'Vrijdag',
        'Zaterdag',
      ],
      dayNamesShort: ['Zon', 'Maa', 'Din', 'Woe', 'Don', 'Vrij', 'Zat'],
      dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
      // tslint:disable-next-line:max-line-length
      monthNames: [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December',
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Maa',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dec',
      ],
      //translations
    });

    let starttijd = moment().set({ hour: 7, minutes: 0 });
    let eindtijd = moment().set({ hour: 19, minutes: 0 });
    for (
      let index = starttijd;
      index < eindtijd;
      index = moment(index).add(30, 'minutes')
    ) {
      this.tijden.push({
        name: moment(index).format('HH:mm'),
        code: moment(index).format('HH:mm'),
      });
    }
  }

  kiesSoort() {
    console.log(this.soort);
    if (this.soort === 'bso') {
      this.pakketten = [
        {
          pakket: 'BSO',
          maandag: false,
          dinsdag: false,
          woensdag: false,
          donderdag: false,
          vrijdag: false,
        },
        {
          pakket: 'BSO Totaal',
          maandag: false,
          dinsdag: false,
          woensdag: false,
          donderdag: false,
          vrijdag: false,
        },
        {
          pakket: 'BSO Vakantie',
          maandag: false,
          dinsdag: false,
          woensdag: false,
          donderdag: false,
          vrijdag: false,
        },
        {
          pakket: 'VSO',
          maandag: false,
          dinsdag: false,
          woensdag: false,
          donderdag: false,
          vrijdag: false,
        },
      ];
    }
  }

  captcha(event) {
    this.messageService.add({
      severity: 'info',
      summary: 'Succees',
      detail: 'User Responded',
      sticky: true,
    });
  }

  onSubmit() {
    console.log('Submit form');

    this.formulier = new Formulier(
      '',
      '',
      '',
      '',
      '',
      null,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      null,
      '',
      '',
      '',
      '',
      '',
      null,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      '',
      null,
      '',
      '',
      null,
      '',
      null,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );

    this.formulier.nieuw = true;

    this.formulier.soort = this.soort;
    this.formulier.lokatie = this.lokatie;

    for (let f in this.myForm.controls) {
      this.formulier[f] = this.myForm.controls[f].value;
    }

    if (this.soort == 'bso') {
      this.formulier.pakket = this.pakketten;
    }

    let url = this._config['settings']['db'] + '/api/formulieren';

    let options = { headers: this.headers };
    let body = this.formulier;
    console.log(url);
    console.log(body);
    console.log(options);

    this.http.post(url, body, options).subscribe((result) => {
      // this._router.navigate(['/dank']);
      this.displayThanks = true;
    });
  }

  closeWindow() {
    this.soort = '';
    // if (this.showHeader) {
    //   window.open('https://www.deberenstad.nl', '_self');
    // } else {
    window.parent.location.href = 'https://www.deberenstad.nl';
    // }
  }

  showInfo(event, opi: OverlayPanel, info) {
    console.log('test');
    opi.toggle(event);

    this.info = info;
  }
}

function requiredIfValidator(predicate) {
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  };
}

function checkEmail(predicate) {
  console.log(predicate);
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  };
}
