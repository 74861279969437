import { Routes, RouterModule } from '@angular/router';
import { DankComponent } from './dank/dank.component';
import { InschrijfComponent } from './inschrijf/inschrijf.component';

const appRoutes: Routes = [
  { path: '', component: InschrijfComponent },
  { path: 'dank', component: DankComponent },
];

export const AppRoutingProviders: any[] = [];

export const routing = RouterModule.forRoot(appRoutes, {
  relativeLinkResolution: 'legacy',
});
