import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-fielderror',
  templateUrl: './fielderror.component.html',
  styleUrls: ['./fielderror.component.scss'],
})
export class FielderrorComponent implements OnInit {
  @Input() form: any;
  @Input() field: any;
  selectedSoort: any;
  soorten: any[];

  constructor() {}

  ngOnInit() {}
}
